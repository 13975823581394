import React from 'react';
import { motion } from 'framer-motion';
import { Container, Row, Col, Form, FormControl, Button } from 'react-bootstrap';
import { BlogData } from '../../../constants/BlogData';
import { Tags } from '../../../constants/BlogData';
import './Blog.scss';

const Blog = () => {
  return (
    <Container fluid className="Container">
      <Row className='TopBlogs'>
        <Col xs={12} md={8}>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Blogs of SARA LMS
          </motion.h1>
        </Col>
        <Col xs={12} md={4}>
          <Form>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Row className='align-items-center'>
                <Col xs={8} md={9}>
                  <FormControl type='text' placeholder='Search' className='mr-sm-2' />
                </Col>
                <Col xs={4} md={3}>
                  <Button variant='outline-success' type='submit'>Search</Button>
                </Col>
              </Row>
            </motion.div>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className='DownBlogs'>
        <Col xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className='RecentPosts'
          >
            <h4>Recent Posts</h4>
            {BlogData.map((data) => (
              <div key={data.id} className='RecentPostsList'>
                <ul>
                  <motion.li
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {data.title}
                  </motion.li>
                  <hr />
                </ul>
              </div>
            ))}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className='Tags'
          >
            <h4>Tags</h4>
            {Tags.map((data) => (
              <motion.p
                key={data.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {data.tags}
              </motion.p>
            ))}
          </motion.div>
        </Col>
        <Col xs={12} md={8}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className='Blogs'
          >
            {BlogData.map((data) => (
              <div key={data.id} className='Blog'>
                <div className='Left'>
                  <motion.h6
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {data.date}
                  </motion.h6>
                  <motion.h5
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {data.title}
                  </motion.h5>
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {data.description}
                  </motion.p>
                  <motion.h6
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    by: {data.author}
                  </motion.h6>
                </div>
                <div className='Right'>
                  <motion.img
                    src={data.image}
                    alt='blogimage'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
              <hr />
              </div>
            ))}
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default Blog;

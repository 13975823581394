// import React, { useState } from 'react';
// import { Form, Button, Container, Row, Col } from 'react-bootstrap';
// import './Signup.scss';
// import { useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2'; 

// const Signup = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     email: '',
//     name: '',
//     password: '',
//     confirmPassword: '',
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSignUp = async (e) => {
//     e.preventDefault();
  
//     try {
//       const token = 'FV14DwYx81XpuakIEncdTAUz3O5Y16qP'; 
//       const response = await fetch('https://sara-api.m2r2.co.uk/api/auth/register', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}` 
//         },
//         body: JSON.stringify({
//           email: formData.email,
//           fullName: formData.name,
//           password: formData.password,
//         }),
//       });
  
//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(errorText || 'Sign up request failed');
//       }
  
//       const userData = await response.json();
//       console.log('User registered successfully:', userData);
  
//       const otpResponse = await fetch('https://sara-api.m2r2.co.uk/api/auth/verifyEmail', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}` 
//         },
//         body: JSON.stringify({ email: formData.email }),
//       });
  
//       if (!otpResponse.ok) {
//         const otpErrorText = await otpResponse.text();
//         throw new Error(otpErrorText || 'Failed to generate OTP');
//       }
  
//       const otpData = await otpResponse.json();
//       console.log('OTP generated successfully:', otpData);
  
//       navigate(`/verify?email=${formData.email}`);
  
//     } catch (error) {
//       console.error('Sign up error:', error.message);
//       Swal.fire({
//         icon: 'error',
//         title: 'Registration Failed',
//         text: error.message,
//       });
//     }
//   };

//   return (
//     <Container className="SignupContainer">
//       <Row className="justify-content-md-center">
//         <Col xs={12} md={6}>
//           <div className="signup-form">
//             <Form onSubmit={handleSignUp}>
//               <Form.Group controlId="formBasicEmail" className="form-email">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleInputChange}
//                   placeholder="Enter email"
//                   required
//                 />
//               </Form.Group>

//               <Form.Group controlId="formBasicName" className="form-name">
//                 <Form.Label>Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleInputChange}
//                   placeholder="Enter your name"
//                   required
//                 />
//               </Form.Group>

//               <Form.Group controlId="formBasicPassword" className="form-group">
//                 <Form.Label>Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   name="password"
//                   value={formData.password}
//                   onChange={handleInputChange}
//                   placeholder="Password"
//                   required
//                 />
//               </Form.Group>

//               <Form.Group controlId="formBasicConfirmPassword" className="form-group">
//                 <Form.Label>Confirm Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   name="confirmPassword"
//                   value={formData.confirmPassword}
//                   onChange={handleInputChange}
//                   placeholder="Confirm password"
//                   required
//                 />
//               </Form.Group>

//               <Button variant="primary" type="submit" className="signup-button">
//                 Sign Up
//               </Button>
//             </Form>

//             <div className="signin-link text-center">
//               <p>
//                 Already have an account? <a href="/signin">Sign In</a>
//               </p>
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default Signup;



import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import './Signup.scss';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      const token = 'FV14DwYx81XpuakIEncdTAUz3O5Y16qP';
      const response = await fetch('https://sara-api.m2r2.co.uk/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          email: formData.email,
          fullName: formData.name,
          password: formData.password,
        }),
      });
      const responseText = await response.text();
      const status = response.status;
      if (!response.ok) {
        throw new Error(responseText || 'Sign up request failed');
      }

      if (status === 200) {
        console.log('User registered successfully:', responseText);
        navigate(`/verify?email=${formData.email}`)
        // const otpResponse = await fetch('https://sara-api.m2r2.co.uk/api/auth/verifyEmail', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${token}`
        //   },
        //   body: JSON.stringify({ email: formData.email }),
        // });

        // const otpResponseText = await otpResponse.text();
        // if (!otpResponse.ok) {
        //   throw new Error(otpResponseText || 'Failed to generate OTP');
        // }

        // console.log('OTP generated successfully:', otpResponseText);

        // Swal.fire({
        //   icon: 'success',
        //   title: 'Registration Successful',
        //   text: 'You have registered successfully. Redirecting to OTP verification...',
        // }).then(() => {
        //   navigate(`/verify?email=${formData.email}`);
        // });
      } else {
        throw new Error('Unexpected response format');
      }

    } catch (error) {
      console.error('Sign up error:', error.message);
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: error.message,
      });
    }
  };

  return (
    <Container className="SignupContainer">
      <Row className="justify-content-md-center">
      <Col xs={12} className="SignupHeader">
          <h2>Sign Up to your M2R2 Customer Account</h2>
          <p>Create an account to oversee all your SARA modules!</p>
        </Col>
        <Col xs={12} md={6}>
          <div className="signup-form">
            <Form onSubmit={handleSignUp}>
              <Form.Group controlId="formBasicEmail" className="form-email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicName" className="form-name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter your name"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Password"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword" className="form-group">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm password"
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="signup-button">
                Sign Up
              </Button>
            </Form>

            <div className="signin-link text-center">
              <p>
                Already have an account? <a href="/signin">Sign In</a>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;







import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { motion } from 'framer-motion';
import { Row, Col } from 'react-bootstrap';
import { ModulePoints } from '../../../constants/ModulePoints';
import './Module.scss';
import ModulesImg from '../../../assets/ModulesImg.svg';
import { animateScroll as scroll } from 'react-scroll';

const Module = () => {
  const moduleRef = useRef(null);

  useEffect(() => {
    scroll.scrollTo(moduleRef.current.offsetTop, {
      smooth: true,
      duration: 500,
    });
  }, []);

  const handleScrollAnimation = () => {
    const moduleRows = document.querySelectorAll('.module-row');

    moduleRows.forEach((row, index) => {
      const top = row.getBoundingClientRect().top;
      const isVisible = top < window.innerHeight - 100;

      if (isVisible) {
        row.classList.add('visible');
      } else {
        row.classList.remove('visible');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollAnimation);

    return () => {
      window.removeEventListener('scroll', handleScrollAnimation);
    };
  }, []);

  return (
    <Container fluid className="Container">
      <motion.h1
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Modules of SARA Library Management System
            </motion.h1>
      <Row className="ModulesContainer">
      <Col xs={12} md={6} className="ModuleRight">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img src={ModulesImg} alt="Modules" style={{ maxWidth: "100%" }} />
        </motion.div>
      </Col>
      <Col xs={12} md={6} className="ModuleLeft">
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="ModuleHeading">
            <motion.h3
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              These modules help in the smooth functioning of Library Management software
            </motion.h3>
          </div>
        </motion.div>
      </Col>
    </Row>
      <Row className="ModulePoints" ref={moduleRef}>
        <Col xl={10} className="ModulePointsColumn">
          <div className="ModulePoints">
            {ModulePoints.map(({ id, icon, title, description }) => (
              <motion.div
                key={id}
                className={`Module-point module-row`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: id * 0.1 }}
              >
                <div className="icon">{icon}</div>
                <div className="TitleandDescription">
                  <motion.h5
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    {title}
                  </motion.h5>
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                  >
                    {description}
                  </motion.p>
                </div>
              </motion.div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Module;

import React from 'react';
import Container from 'react-bootstrap/Container';
import { motion } from 'framer-motion';
import { Row, Col, Card,} from 'react-bootstrap';
import Aboutusimg from '../../../assets/Aboutusimg.png';
import LMSSupport from '../../../assets/LMSSupport.png'
import { Aboutuspoints } from '../../../constants/Aboutuspoints';
import { AboutusCardsData } from '../../../constants/AboutusCardsData';
import {BenefitsData} from '../../../constants/BenefitsData';
import './Aboutus.scss'

const Aboutus = () => {
  return (
    <Container fluid className="Container">
      <Row className='AboutusContainer'>
      <Col xl={8} className='AboutusTextColumn'>
        <motion.div
          className='AboutusLeftText'
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Introducing SARA: The Future of Library Management
          </motion.h2>
          <motion.div
            className='AboutusPoints'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {Aboutuspoints.map(({ id, icon, point }) => (
              <motion.div
                key={id}
                className="Aboutus-point"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.1 * id }}
              >
                <div className="icon">{icon}</div>
                <div className="point">{point}</div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </Col>
      <Col xl={4} className="AboutusImage">
        <motion.img
          src={Aboutusimg}
          alt="LMS"
          className="Aboutus"
          width={400}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        />
      </Col>
    </Row>
    <Row className='AboutusCards'>
      {AboutusCardsData.map((card) => (
        <Col key={card.id} xs={12} sm={6} md={4} lg={4} xl={4}>
          <motion.div
            className='CustomCard'
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: card.id * 0.1 }}
          >
            <Card>
              <motion.img
                src={card.image}
                alt={card.title}
                className='CardImage'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
              <Card.Body>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <Card.Title className='CardHeading'>{card.title}</Card.Title>
                  <Card.Text className='CardDescription'>{card.description}</Card.Text>
                </motion.div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      ))}
    </Row>
    <Row className='BenefitsContainer'>
      <Col xs={12}>
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Benefits of SARA LMS
        </motion.h1>
      </Col>
      {BenefitsData.map((benefit) => (
        <Col key={benefit.id} xs={12} sm={6} md={4}>
          <motion.div
            className='Benefits'
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: benefit.id * 0.1 }}
          >
            <div className='Icon'>{benefit.icon}</div>
            <div className='Title'>
              <motion.h4
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                {benefit.title}
              </motion.h4>
            </div>
            <div className='Description'>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                {benefit.description}
              </motion.p>
            </div>
          </motion.div>
        </Col>
      ))}
    </Row>
    <Row className='SupportContainer'>
<Col xs={12} sm={8} md={8} className='LeftContainer'>
<motion.div
initial={{ opacity: 0, y: 50 }}
animate={{ opacity: 1, y: 0 }}
transition={{ duration: 0.5 }}
>
<h3>A dedicated team to support you</h3>
<p>
Our dedicated team at SARA LMS is committed to providing exceptional support throughout your library management system journey. From setup to ongoing maintenance, we're here to ensure your success and satisfaction with our software.
</p>
<motion.a
  whileHover={{ scale: 1.1 }}
  whileTap={{ scale: 0.9 }}
  transition={{ duration: 0.2 }}
  className=" btn contact-us-btn"
  href="/contactus"
>
  Contact Us
</motion.a>
</motion.div>
</Col>
<Col xs={12} sm={4} md={4} className='RightContainer'>
<motion.div
initial={{ opacity: 0, x: 50 }}
animate={{ opacity: 1, x: 0 }}
transition={{ duration: 0.5 }}
>
<img src={LMSSupport} alt="LMS Support" className="img-fluid" />
</motion.div>
</Col>
</Row>
    </Container>
  );
};

export default Aboutus;

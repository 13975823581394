import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Aboutus from './components/pages/Aboutus/Aboutus';
import Blog from './components/pages/Blog/Blog';
import Contactus from './components/pages/Contactus/Contactus';
import Demo from './components/pages/Demo/Demo';
import Home from './components/pages/Home/Home';
import Module from './components/pages/Module/Module';
import Signin from './components/Auth/Signin/Signin';
import Signup from './components/Auth/Signup/Signup';
import ForgotPassword from './components/Auth/ForgotPassword/ForgotPassword';
import Subscription from './components/pages/Subscription/Subscription';
import SubscriptionConnection from './components/pages/Subscription/Subscription/SubscriptionConnection';
import SubscriptionSuccessPage from './components/pages/Subscription/SubscriptionSuccessPage/SubscriptionSuccessPage';
import CookiePolicy from './components/pages/Policies/CookiePolicy/CookiePolicy';
import TermsOfUse from './components/pages/Policies/TermsOfUse/TermsOfUse';
import PrivacyPolicy from './components/pages/Policies/PrivacyPolicy/PrivacyPolicy';
import EmailVerification from './components/Auth/EmailVerification/EmailVerification';
import UpdateSubscription from './components/pages/Subscription/UpdateSubscription/UpdateSubscription'

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/module" element={<Module />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/checkout" element={<SubscriptionConnection />} />
          <Route path="/success" element={<SubscriptionSuccessPage />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
          <Route path="/verify" element={<EmailVerification />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/update-subscription" element={<UpdateSubscription />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;

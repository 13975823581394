import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Container, Row, Col } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import './CheckoutForm.scss';
import SARAlogo from '../../../../assets/SARAlogo.png';
import Loadinggif from '../../../../assets/loadinggif.gif';
import cookieHandler from '../../../../cookieHandler.js';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const URL = process.env.REACT_APP_URL;
const LoadingModal = () => (
  <div className="loading-modal">
    <div className="loading-content">
      <img src={Loadinggif} alt="Loading..." />
    </div>
  </div>
);


const CheckoutForm = () => {
  const [processing, setProcessing] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState(null);
  let [searchParams] = useSearchParams();
  const priceId = searchParams.get('priceId');
  const title = searchParams.get('title');
  const description = searchParams.get('description'); 
  const price = searchParams.get('price'); 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    (async () => {
      const emailId = cookieHandler.getCookie('email');
      if(emailId){
         await setEmail(emailId);
      }
    })()
  
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setShowLoading(true);

    try {

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email: email,
          address: {
            line1: addressLine,
            country: 'IN', 
          },
        },
      });

      if (error) {
        console.error('Payment method creation failed:', error);
        setError(error.message);
        setProcessing(false);
        setShowLoading(false);
        return;
      }
      // const response = await fetch('http://localhost:4000/create-subscription', {
      const response = await fetch(`${API_ENDPOINT}/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId: priceId,
          paymentMethodId: paymentMethod.id,
          name: name,
          email: email,
          address: {
            // city: city,
            country: 'US', 
            line1: addressLine,
            // postal_code: postalCode,
            // state: state
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Subscription creation failed');
      }

      const responseData = await response.json();

      localStorage.setItem('priceId',responseData.priceId);
      localStorage.setItem('subscriptionId',responseData.subscriptionId);

      await new Promise(resolve => setTimeout(resolve, 1000));

      console.log('Subscription created successfully:', responseData);
      console.log('url',URL);
      stripe.confirmPayment({
        clientSecret: responseData.clientSecret,
        confirmParams: {
          // return_url: 'https://beta.lms.m2r2.co.uk/success',
          return_url: `${URL||'https://dev.lms.m2r2.co.uk'}/success`
        },
      })
      .then(result => {
        if (result.error) {
          console.error(result.error);
        }
      });
    } catch (error) {
      setError(error.message);
      console.error('Subscription creation failed:', error.message);
    } finally {
      setProcessing(false);
      setShowLoading(false);
    }
  };

  return (
    <Container>
      <Row className='Container'>
        <Col className='LeftColumn'>
          <div className='LeftContainer'>
            <div className='Logo'>
              <img src={SARAlogo} alt="saralogo" width={150} />
            </div>
            <div className='Heading'>
              <h2>Subscription Summary</h2>
              <h5>Selected Plan : <span>{title}</span></h5>
              <h5>Plan Description : <span>{description}</span></h5>
              <h5>Plan price : <span>£ {price}</span></h5>
              <h5>GST : <span>£ 0</span></h5>
              <hr />
              <h5>Total : <span>£ {price}</span> </h5>
            </div>
          </div>
        </Col>
        <Col className='RightColumn'>
          <div className='checkout-form-container'>
            <h2>Subscription Payment Method</h2>
            <form onSubmit={handleSubmit}>
              <div className='input-group'>
                <h3>Contact information</h3>
                <p style={{border:"1px solid grey",padding:"3px 7px",borderRadius:"10px"}}>Email : {email}</p>
                 <input
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Card Holder Name'
                  required
                />
                {/*
                <input
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Your Email'
                  required
                /> */}
                {/* <input
                  type='text'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder='City'
                  required
                /> */}
                <input
                  type='text'
                  value={addressLine}
                  onChange={(e) => setAddressLine(e.target.value)}
                  placeholder='Full Address'
                  required
                />
                {/* <input
                  type='text'
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder='Postal Code'
                  required
                /> */}
                {/* <input
                  type='text'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  placeholder='State'
                  required
                /> */}
              </div>
              <div className='card-element'>
                <CardElement />
                <br />
                <p style={{fontSize:"12px"}}>By providing your card information, you allow SARA Inc to charge your card for future payments in accordance with their terms.</p>
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type='submit' className='subscribe-button' disabled={!stripe || processing}>
                {processing ? 'Processing...' : 'Subscribe'}
              </button>
            </form>
            {showLoading && <LoadingModal />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutForm;

import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import ProtectedRoute from "../../ProtectedRoute";

const stripePromise = loadStripe('pk_test_51P69n1SFP1FkdmVxJTm1M51Z6rNLcSiOktODpSr05LkD9MGUOOf5HWYLhSV1VIrDOgZefEpZP9SvHkn3RvsDp3h900dR4tMDf0');

const SubscriptionConnection = ({ priceId, title, description, price }) => {
  return (
    <ProtectedRoute>
    <Elements stripe={stripePromise}>
      <CheckoutForm priceId={priceId} title={title} description={description} price={price} />
    </Elements>
    </ProtectedRoute>
  );
};

export default SubscriptionConnection;

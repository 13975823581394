export const Essential =[
    {
        id:1,
        title:"Personal (£1/month)",
        description: "loreum ipsum",
        Price:1,
        points:[
            {
                point:"Catalog Management"
            },
            {
                point:"Member Management (up to 500 members)"
            },
            {
                point:"Check-in/Check-out"
            },
            {
                point:"Basic Reporting"
            },
            {
                point:"Ideal for : Small libraries or startups looking for essential library management tools. "
            }
        ],
        priceId:'price_1PGIkKSFP1FkdmVxALFe7m2d'
    },
    {
        id:2,
        title:"Home (£3/month)",
        description: "loreum ipsum",
        Price:3,
        points:[
            {
                point:"Unlimited Members"
            },
            {
                point:"Advanced Reporting"
            },
            {
                point:"Fine Management"
            },
            {
                point:"RFID Integration"
            },
            {
                point:" Ideal for : Growing libraries needing comprehensive management and reporting capabilities."
            }
        ],
        priceId:'price_1PGIkzSFP1FkdmVxj80yaEk5'
    },
    {
        id:3,
        title:"School (£7/month)",
        description: "loreum ipsum",
        Price:7,
        points:[
            {
                point:"Multi-branch Support"
            },
            {
                point:"Customizable Dashboards"
            },
            {
                point:"API Access for Integration"
            },
            {
                point:"Priority Support"
            },
            {
                point:"Ideal for :  Libraries with multiple branches or complex needs requiring advanced customization and integration."
            }
        ],
        priceId:'price_1PGIloSFP1FkdmVxougem4eY'

    },
];

export const Enterprise =[
    {
        id:1,
        title:"Sandard (£199/month)",
        description: "loreum ipsum",
        Price:199,
        points:[
            {
                point:"Catalog Management"
            },
            {
                point:"Member Management (up to 500 members)"
            },
            {
                point:"Check-in/Check-out"
            },
            {
                point:"Basic Reporting"
            },
            {
                point:"Ideal for : Small libraries or startups looking for essential library management tools. "
            }
        ],
        priceId:'price_1PGIjQSFP1FkdmVxOp8QB7sf'
    },
    {
        id:2,
        title:"Premium (£399/month)",
        description: "loreum ipsum",
        Price:399,
        points:[
            {
                point:"Unlimited Members"
            },
            {
                point:"Advanced Reporting"
            },
            {
                point:"Fine Management"
            },
            {
                point:"RFID Integration"
            },
            {
                point:" Ideal for : Growing libraries needing comprehensive management and reporting capabilities."
            }
        ],
        priceId:'price_1PGIiwSFP1FkdmVx8wMMvPyi'

    },
    {
        id:3,
        title:"Advanced (£799/month)",
        description: "loreum ipsum",
        Price:799,
        points:[
            {
                point:"Multi-branch Support"
            },
            {
                point:"Customizable Dashboards"
            },
            {
                point:"API Access for Integration"
            },
            {
                point:"Priority Support"
            },
            {
                point:"Ideal for :  Libraries with multiple branches or complex needs requiring advanced customization and integration."
            }
        ],
        priceId:'price_1PGIhzSFP1FkdmVxqOqEkeQN'
    },
];
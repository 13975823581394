import React,{useState, useEffect, useRef} from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Accordion, Button, Form, } from 'react-bootstrap';
import Carousel from 'react-multi-carousel'; 
import 'react-multi-carousel/lib/styles.css';
import { motion } from 'framer-motion';
import Dashboard from '../../../assets/Dashboard.png';
import Acquisition from '../../../assets/Acquisition.svg'
import Cataloguing from '../../../assets/Cataloguing.svg';
import HomeMainImage from '../../../assets/HomeMainImage.svg'
import Circulation from '../../../assets/Circulation.svg'
import LibrariesIcon from '../../../assets/LibrariesIcon.svg'
import MembersIcon from '../../../assets/MembersIcon.svg'
import BooksMaintainedICon from '../../../assets/BooksMaintainedIcon.svg'
import BooksIssuedIcon from '../../../assets/BooksIssuedIcon.svg'
import {Features} from '../../../constants/Features';
import {AcquisitionPoints} from '../../../constants/AcquisitionPoints';
import {CataloguingPoints} from '../../../constants/Cataloguing';
import {faqData} from '../../../constants/FAQ';
import {BlogData} from '../../../constants/BlogData';
import './Home.scss';
import RequestDemo from '../RequestDemo/RequestDemo';

function Home() {

  const [showRequestDemo, setShowRequestDemo] = useState(false);

  const words = ['Cloud Native', 'Modular', 'Scalable'];
  const [visibleWordIndex, setVisibleWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1000);

    return () => clearInterval(interval);
  }, [words.length]);

  const handleRequestDemoClick = () => {
    setShowRequestDemo(true);
  };

  const handleCancelRequest = () => {
    setShowRequestDemo(false);
  };

  const FeaturesCarousel = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (FeaturesCarousel.current) {
        FeaturesCarousel.current.next();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid className="Container">
       <Row className='HomeMainContainer'>
      <Col xs={12} lg={7}>
        <motion.div 
          className='Heading'
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h1>
      Welcome to{' '}
      {words.map((word, index) => (
        <span
          key={index}
          className={`word ${index === visibleWordIndex ? 'visible' : ''}`}
        >
          {word}
        </span>
      ))}
       
    </h1>
        </motion.div>
        <motion.div 
          className='Description'
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <p>Easily monitor, evaluate, and perform regular library tasks with outstanding productivity</p>
          <p><strong>Educational Vision:</strong> From classrooms to virtual environments, our LMS empowers your vision for modern education</p>
          <p><strong>Learning Everywhere:</strong> Expand your reach and enable seamless learning experiences for students and educators worldwide</p>
        </motion.div>
        <div className='Button'>
          <div className='Requestbutton'>
            <motion.div 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Button className="sign-in-btn" onClick={handleRequestDemoClick}>REQUEST DEMO</Button>
            </motion.div>
          </div>
          <div className='PricingButton'>
            <motion.div 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Button className="sign-in-btn" href={`/subscription`}>PRICING</Button>
            </motion.div>
          </div>
        </div>
      </Col>
      {showRequestDemo && (
        <RequestDemo onCancelRequest={handleCancelRequest} />
      )}
      <Col xs={12} lg={5}>
        <motion.img
          src={HomeMainImage}
          alt="HomeMainImage"
          className="DashboardImage"
          style={{ width: '100%' }}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 2}}
        />
      </Col>
    </Row>
    <Row className='Formcontainer'>
      <Col xs={12} md={12}>
        <h1 className="mb-4">Fill out the Contact Form Below</h1>
        <Form className="custom-form">
          <Row className="mb-3">
            <Col xs={12} md={3}>
              <Form.Group controlId="nameInput">
                <Form.Control type="text" placeholder="Your Name" />
              </Form.Group>
            </Col>  
            <Col xs={12} md={3}>
              <Form.Group controlId="emailInput">
                <Form.Control type="email" placeholder="Your Email" />
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group controlId="mobileInput">
                <Form.Control type="text" placeholder="Your Mobile No" />
              </Form.Group>
            </Col>
            <Col xs={12} md={3} className="d-grid align-items-end">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
    <Row className="FeaturesContainer">
        <motion.h2
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1>Features of SARA Library Management System</h1>
        </motion.h2>
        <Carousel
          ref={FeaturesCarousel}
          additionalTransfrom={0}
          arrows={false}
          centerMode={false}
          className="Carousel"
          containerClass="container-with-dots"
          customTransition="transform 500ms ease-in-out"
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          slidesToSlide={1}
          swipeable
        >
          {Features.map((feature, idx) => (
            <motion.div
              key={idx}
              className="FeatureItem"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: idx * 0.1 }}
            >
              <div className="Card">
                <div className="Icon">{feature.icon}</div>
                <div className="Title">
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    {feature.Title}
                  </motion.p>
                </div>
                <div className="Description">
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    {feature.Description}
                  </motion.p>
                </div>
              </div>
            </motion.div>
          ))}
        </Carousel>
      </Row>
      <Row className='LMSIntroduction'>
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className='LMSHeader'
      >
        SARA Library Management System
      </motion.h1>
      <Col xs={12} sm={12} md={6} xl={6} className='LMSColumnone'>
        <motion.img
          src={Dashboard}
          alt="Dashboard"
          className="DashboardImage"
          style={{width: '100%',}}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
        />
      </Col>
      <Col xs={12} sm={12} md={6} xl={6} className='LMSColumntwo'>
        <div className='Paragraph'>
          <div className='ParagraphOne'>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              The SARA Library Management System is a specialized software designed to streamline and automate all library operations. It empowers librarians to efficiently manage databases of branches, new acquisitions, patron management, etc.
            </motion.p>
          </div>
          <div className='ParagraphTwo'>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              SARA Library Management System facilitates meticulous library record-keeping. It tracks the library's inventory, managing book circulation, returns, renewals, and transactions.
            </motion.p>
          </div>
        </div>
      </Col>
    </Row>
    <hr />
    <Row className='AcquisitionContainer'>
      {/* For extra small (xs) and small (sm) screens, use full width (12 columns) */}
      <Col xs={12} sm={12} md={6} xl={6}>
        <motion.img
          src={Acquisition}
          alt="Acquisition"
          className="AcquistionImage"
          style={{width: '100%',}}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        />
      </Col>
      {/* For medium (md) and larger screens (lg, xl), use half width (6 columns) */}
      <Col xs={12} sm={12} md={6} xl={6} className='AcquisitionTextColumn'>
        <motion.div
          className='AcquistionRightText'
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            ACQUISITION
          </motion.h2>
          <motion.div
            className='AcquistionParagraph'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <p>
              The Acquisition module within our SARA Library Management System facilitates every step of the acquisition process, from proposal submission to payment and accessioning. Whether you operate multiple libraries, utilize centralized purchasing, or manage distributed ordering, SARA's acquisition system efficiently handles diverse workflows.
            </p>
          </motion.div>
          <motion.div
            className='AcquisitionPoints'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            {AcquisitionPoints.map(({ id, icon, point }) => (
              <div key={id} className="acquisition-point">
                <div className="icon">{icon}</div>
                <div className="point">{point}</div>
              </div>
            ))}
          </motion.div>
        </motion.div>
      </Col>
    </Row>
    <hr />
    <Row className='CataloguingContainer'>
      <Col xs={12} sm={12} md={6} xl={6} className='CataloguingTextColumn'>
        <motion.div
          className='CataloguingLeftText'
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            CATALOGUING
          </motion.h2>
          <motion.div
            className='CataloguingParagraph'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <p>
              Catalog your records efficiently with SARA's user-friendly, highly flexible, and powerful cataloging module. This module allows for cataloging of all types of materials, including physical items and digital resources, in a smarter and faster manner.
            </p>
          </motion.div>
          <motion.div
            className='CataloguingPoints'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            {CataloguingPoints.map(({ id, icon, point }) => (
              <div key={id} className="Cataloguing-point">
                <div className="icon">{icon}</div>
                <div className="point">{point}</div>
              </div>
            ))}
          </motion.div>
        </motion.div>
      </Col>
      <Col xs={12} sm={12} md={6} xl={6}>
        <motion.img
          src={Cataloguing}
          alt="Cataloguing"
          className="CataloguingImage"
          style={{width: '100%',}}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        />
      </Col>
    </Row>
    <hr />
    <Row className='CirculationContainer'>
      <Col xs={12} sm={12} md={6} xl={6}>
        <motion.img
          src={Circulation}
          alt="Circulation"
          className="CirculationImage"
          style={{width: '100%',}}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        />
      </Col>
      <Col xs={12} sm={12} md={6} xl={6} className='CirculationTextColumn'>
        <motion.div
          className='CirculationRightText'
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            CIRCULATION
          </motion.h2>
          <motion.div
            className='CirculationParagraph'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <p>
              The Circulation module in SARA Library Management System offers comprehensive solutions for front desk operations, barcode and spine label printing, stocktaking, and automated overdue reminders.
            </p>
            <p>
              Integrated with Smart Cards and RFID technology, the system ensures accurate and efficient issue/return processes and hassle-free stock verification.
            </p>
            {/* <p>
              Gain valuable insights with a range of reports and statistics available through SARA, enabling better understanding of library usage, informed collection development, and enhanced staff efficiency and service standards.
            </p> */}
          </motion.div>
        </motion.div>
      </Col>
    </Row>
    <Row className='FreedemoContainer'>
        <Col>
          <div className='row'>
            <h1>Book a FREE demo today and get our SARA Library Management System (worth £350) free for 1 month!*</h1>
            <Button className='FreedemoButton' onClick={handleRequestDemoClick}>REQUEST DEMO</Button>
          </div>
        </Col>
      </Row>
    <Row className='Testimonials'>
        <Col xl={6} className='LeftContainer'>
            <p>Empowering Libraries for <span>Modernization</span></p>
            <p>Achieving Success through Commitment and Innovation</p>
        </Col>
        <Col xl={6} className='RightContainer'>
           <Row>
            <Col>
              <div className='LibrariesandBooksmaintained'>
                    <div className='Libraries'>
                        <div className='Icon'>
                          <img src={LibrariesIcon} alt="librariesicon" />
                        </div>
                        <div className='numberandtitle'>
                          <h5>150</h5>
                          <p>Libraries</p>
                        </div>
                    </div>
                    <div className='BooksMaintained'>
                        <div className='Icon'>
                          <img src={BooksMaintainedICon} alt="BooksMaintainedIcon" />
                        </div>
                        <div className='numberandtitle'>
                          <h5>5,25,678</h5>
                          <p>Books Maintained</p>
                    </div>
                    </div>
              </div>
            </Col>
            <Col>
            <div className='MembersandBooksissued'>
              <div className='Members'>
                        <div className='Icon'>
                          <img src={MembersIcon} alt="MembersIcon" />
                        </div>
                        <div className='numberandtitle'>
                          <h5>3,75,467</h5>
                          <p>Members</p>
                        </div>
                    </div>
                    <div className='BooksIssued'>
                        <div className='Icon'>
                          <img src={BooksIssuedIcon} alt="BooksissuedIcon" />
                        </div>
                        <div className='numberandtitle'>
                          <h5>15,01,868</h5>
                          <p>Books Issued</p>
                    </div>
                    </div>
              </div>
            </Col>
           </Row>
        </Col>
    </Row>
    <Row className="justify-content-center">
    <h1>FAQs On SARA LMS</h1>
      <Col xs={12}>
      <Accordion defaultActiveKey={['0']} flush className='Faqcard'>
      {faqData.map((item, index) => (
        <Accordion.Item key={index} eventKey={`${index}`} className='Faqitem'>
          <Accordion.Header><h6>{item.question}</h6></Accordion.Header>
          <Accordion.Body>{item.answer}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
      </Col>
    </Row>
    <Row className='BlogContainer'>
  <Col>
    <div className='TopText'>
      <h2>Our Blogs</h2>
      <p>
        Explore the Nextcent blog for the latest insights on memberships, trends, and more.
        Discover new community members and learn how organizations are boosting their membership
        income and much more.
      </p>
    </div>
    <div className='BottomBlogs'>
      <Row className='InnerRow'>
        {BlogData.map(blog => (
          <Col key={blog.id} xs={12} sm={6} md={4} className='BlogCol'>
            <div className='Blogimageandtext'>
              <div className='BlogImage'>
                <img src={blog.image} alt='blogimage' style={{ width: '100%' }} />
              </div>
              <div className='Blogtextcontainer'>
                <div className='Blogtext'>
                  <h4>{blog.title}</h4>
                  <p>{blog.description}</p>
                  <div className='BlogButton'>
                    <a href={`/blog`}>Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  </Col>
</Row>
    </Container>
  );
}

export default Home;


